(() => {
    const calculateViewportWidthUnit = () => {
        const { documentElement, body } = window.document;
        const vw = documentElement.getBoundingClientRect().width / 100;

        body.style.setProperty('--vw', `${vw}px`);
    };

    window.addEventListener('DOMContentLoaded', calculateViewportWidthUnit);
    window.addEventListener('resize', calculateViewportWidthUnit);
})();
